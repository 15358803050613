




































































import { defineComponent, reactive } from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        type: "withLogin",
        name: "",
        email: "",
        password: "",
        server: "",
        port: 587,
        defaultAddress: false,
      }),
    },
  },

  setup(props, { root }) {
    const model = reactive({
      editor: null,
    });

    const state = reactive({
      showPassword: false,
    });

    const portsList = [25, 465, 587];

    const rules = {
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      password: [(v: string) => !!v || root.$tc("rules.password")],
      server: [
        (v: string) => (!!v && validator.isFQDN(v)) || root.$tc("rules.server"),
      ],
    };

    return { model, state, portsList, rules };
  },
});
